import React, { useCallback } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react-lite';

import { useStore } from 'src/store/store';

const AlertView = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const anchor = {
  vertical: 'top',
  horizontal: 'center',
};

const Alert = observer(() => {
  const { alert } = useStore();
  const handleClose = useCallback(() => {
    alert.reset();
  }, [alert]);
  return (
    <Snackbar
      anchorOrigin={anchor}
      open={alert.hasMessage}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <AlertView severity={alert.type}>{alert.message}</AlertView>
    </Snackbar>
  );
});

export default Alert;

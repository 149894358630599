import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Divider,
  CardContent,
  TextField,
  Box,
  Button,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import * as Yup from 'yup';
import { BasicForm, FormField, CheckBox } from 'src/components/form';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';

const formValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(255)
    .required('Name is required'),
});

const useStyles = makeStyles(them => ({
  buttonCancel: {
    marginRight: them.spacing(2),
  },
  checkboxContainer: {
    marginTop: them.spacing(2),
  },
}));

const CategoryForm = observer(props => {
  const classer = useStyles();
  const { category } = useStore();
  let { editId } = props;
  if (editId) {
    editId = parseInt(editId, 10);
  }
  const { rootCategories, editingCategory } = category;
  const [initialValue, setInitialValue] = useState({
    name: '',
    parentId: '',
    isHidden: false,
    isHiddenInNew: false,
  });

  useEffect(() => {
    category.fetchRootCategories();
    if (editId) {
      category.fetchCategory(editId);
    }
  }, [category, editId]);

  const handleSave = useCallback(
    values => {
      if (editId) {
        category.update(editId, values);
      } else {
        category.create(values);
      }
    },
    [category, editId]
  );

  useEffect(() => {
    if (editId && editingCategory.id) {
      setInitialValue({
        name: editingCategory.name,
        parentId: editingCategory.parentId,
        isHidden: editingCategory.isHidden,
        isHiddenInNew: editingCategory.isHiddenInNew,
      });
    }
  }, [editingCategory.id, editId]);

  return (
    <BasicForm
      title="Category"
      subheader={`${editId ? 'Edit Category' : 'Create new category'}`}
      initialValues={initialValue}
      validationSchema={formValidationSchema}
      enableReinitialize
      onSubmit={handleSave}
    >
      <CardContent>
        <FormField
          component={TextField}
          fullWidth
          label="Name"
          margin="normal"
          name="name"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          select
          label="Parent"
          margin="normal"
          name="parentId"
          variant="outlined"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {rootCategories &&
            rootCategories
              .filter(cat => cat.id !== editId)
              .map(cat => {
                return (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                );
              })}
        </FormField>
        <CheckBox className={classer.checkboxContainer} name="isHidden" label="Hidden" />
        <CheckBox
          className={classer.checkboxContainer}
          name="isHiddenInNew"
          label="Hidden In New"
        />
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          variant="contained"
          component={NavLink}
          to="/app/categories/"
          className={classer.buttonCancel}
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" variant="contained" disabled={category.loading}>
          Save
        </Button>
      </Box>
    </BasicForm>
  );
});

export default CategoryForm;

import axios from 'axios';

export class ApiError {
  status;

  data;

  constructor(status, data) {
    this.status = status;
    this.data = data;
  }
}

export class ApiService {
  _authToken = null;

  _baseApiUrl = null;

  constructor() {
    this._baseApiUrl = process.env.REACT_APP_BASE_API_URL;
  }

  setAuthToken(token) {
    this._authToken = token;
  }

  get(api, options) {
    return this._request(api, {
      ...options,
      method: 'GET',
    });
  }

  post(api, options) {
    return this._request(api, {
      ...options,
      method: 'POST',
    });
  }

  put(api, options) {
    return this._request(api, {
      ...options,
      method: 'PUT',
    });
  }

  delete(api, options) {
    return this._request(api, {
      ...options,
      method: 'DELETE',
    });
  }

  async _request(api, options) {
    let headers = {
      ...options?.headers,
      'X-Version': process.env.REACT_APP_API_VERSION,
    };
    console.log({ headers });
    if (this._authToken) {
      headers = {
        ...headers,
        Authorization: `Bearer ${this._authToken}`,
      };
    }

    try {
      const response = await axios({
        ...options,
        url: `${this._baseApiUrl}/${api}`,
        headers,
        responseType: 'json',
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        throw new ApiError(err.response.status, err.response.data);
      } else {
        throw err;
      }
    }
  }
}

import { makeAutoObservable, flow } from 'mobx';

import { contactMessageService } from '../services';

const defaultFilter = {
  name: null,
  email: null,
  all: false,
};
export class ContactMessageStore {
  loading;

  contactMessages = [];

  page = 1;

  rootStore;

  filter = defaultFilter;

  editingContactMessage = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  fetchContactMessages = flow(function*() {
    this.loading = true;
    try {
      const result = yield contactMessageService.contactMessages(this.filter, this.page);
      this.contactMessages = result.contactMessages;

      this.total = result.total;
    } catch (err) {
      console.log('TCL: ContactMessageStore -> fetchContactMessages -> err', err);

      this.err = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  fetchContactMessage = flow(function*(messageId) {
    this.loading = true;
    try {
      const result = yield contactMessageService.contactMessage(messageId);
      this.editingContactMessage = result || {};
    } catch (err) {
      console.error(err);
      this.error = err;
      this.editingContactMessage = {};
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  deleteContactMessage = flow(function*(messageId) {
    this.loading = true;
    try {
      const result = yield contactMessageService.deleteContactMessage(messageId);
      if (result.ok) {
        yield this.fetchContactMessages();
        this.rootStore.alert.setSuccessMessage('ContactMessage has been removed');
      } else {
        this.rootStore.alert.setErrorMessage('Failed to remove contactMessage');
      }
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  changePage = flow(function*(newPage) {
    if (newPage === this.page) {
      return;
    }
    this.page = newPage;
    yield this.fetchContactMessages();
  });
}

import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import CategoryListView from 'src/views/category/CategoryListView';
import CategoryFormView from 'src/views/category/CategoryFormView';
import BookListView from 'src/views/book/BookListView';
import BookFormView from 'src/views/book/BookFormView';
import AuthorListView from 'src/views/author/AuthorListView';
import AuthorFormView from 'src/views/author/AuthorFormView';
import UserListView from 'src/views/user/UserListView';
import UserFormView from 'src/views/user/UserFormView';
import CrawledBookListView from 'src/views/crawledBook/CrawledBookListView';
import CrawledBookFormView from 'src/views/crawledBook/CrawledBookFormView';
import ContactMessageListView from 'src/views/contactMessage/ContactMessageListView';
import ContactMessageFormView from 'src/views/contactMessage/ContactMessageFormView';
import NewsletterListView from './views/newsletter/NewsletterListView';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'categories', element: <CategoryListView /> },
      { path: 'categories/create', element: <CategoryFormView title="Create Category" /> },
      { path: 'categories/edit/:id', element: <CategoryFormView title="Edit Category" /> },
      { path: 'books', element: <BookListView /> },
      { path: 'books/create', element: <BookFormView title="Create Book" /> },
      { path: 'books/edit/:id', element: <BookFormView title="Edit Book" /> },
      { path: 'account', element: <AccountView /> },
      { path: 'authors', element: <AuthorListView /> },
      { path: 'authors/create', element: <AuthorFormView title="Create Author" /> },
      { path: 'authors/edit/:id', element: <AuthorFormView title="Edit Author" /> },
      { path: 'users', element: <UserListView /> },
      { path: 'users/views/:id', element: <UserFormView title=" View User" /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'crawledBooks', element: <CrawledBookListView /> },
      { path: 'crawledBooks/edit/:id', element: <CrawledBookFormView title="Edit Crawled Book" /> },
      { path: 'contactMesssages', element: <ContactMessageListView /> },
      {
        path: 'contactMesssages/views/:id',
        element: <ContactMessageFormView title=" View ContactMessage" />,
      },
      { path: 'newletters', element: <NewsletterListView /> },
      { path: '', element: <DashboardView /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;

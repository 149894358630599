import { makeAutoObservable, flow } from 'mobx';

import { userService } from '../services';

export class UserStore {
  loading;

  users = [];

  page = 1;

  rootStore;

  filter = {
    email: null,
    name: null,
    all: false,
    provider: null,
    role: 3,
  };

  editingUser = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  fetchUsers = flow(function*() {
    this.loading = true;
    try {
      const reusult = yield userService.fetchUsers(
        {
          ...this.filter,
          sort: 'id desc',
        },
        this.page
      );
      this.users = reusult.users;
      this.total = reusult.total;
    } catch (err) {
      console.log('TCL: UserStore -> fetchUser -> err', err);

      this.err = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  fetchUser = flow(function*(userId) {
    this.loading = true;
    try {
      const user = yield userService.fetchUser(userId);
      this.editingUser = user || {};
    } catch (err) {
      console.error(err);
      this.error = err;
      this.editingUser = {};
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  changePage = flow(function*(newPage) {
    if (newPage === this.page) {
      return;
    }
    this.page = newPage;
    yield this.fetchUsers();
  });

  setSearchFilter = flow(function*(nameSearch, email, provider) {
    this.filter.name = nameSearch || null;
    this.filter.email = email || null;
    this.filter.email = email || null;
    this.filter.provider = provider || null;
    yield this.fetchUsers();
  });
}

import { makeAutoObservable, flow } from 'mobx';
import { bookService } from '../services';

const defaultFilter = {
  name: null,
  categoryId: null,
  authorId: null,
  all: false,
  lang: null,
  bookType: null,
};
export class BookStore {
  loading;

  error;

  books = [];

  total = 0;

  editingBook = {};

  page = 1;

  filter = defaultFilter;

  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  resetFilter() {
    this.filter = defaultFilter;
  }

  fetchBooks = flow(function*() {
    this.editingBook = {};
    this.loading = true;
    try {
      const result = yield bookService.fetchBooks(
        {
          ...this.filter,
          sort: 'id desc',
        },
        this.page
      );
      this.books = result.books;
      this.total = result.total;
    } catch (err) {
      console.error(err);
      this.books = [];
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  createBook = flow(function*(bookFormData) {
    try {
      this.loading = true;
      yield bookService.createBook(bookFormData);
      this.rootStore.alert.setSuccessMessage('Book data saved');
      this.rootStore.route.setRoute('/app/books', { replace: true });
    } catch (err) {
      console.log('Create book error', err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  updateBook = flow(function*(id, bookFormData) {
    try {
      this.loading = true;
      yield bookService.updateBook(id, bookFormData);
      this.rootStore.alert.setSuccessMessage('Book data saved');
    } catch (err) {
      console.log('Update book error', err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  fetchBook = flow(function*(bookId) {
    this.editingBook = {};
    try {
      this.loading = true;
      this.editingBook = yield bookService.fetchBook(bookId);
    } catch (err) {
      console.log('Create book error', err);
      this.editingBook = {};
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  deleteBook = flow(function*(bookId) {
    this.loading = true;
    try {
      const result = yield bookService.deleteBook(bookId);
      if (result.ok) {
        yield this.fetchBooks();
        this.rootStore.alert.setSuccessMessage('Book has been removed');
      } else {
        this.rootStore.alert.setErrorMessage('Failed to remove book');
      }
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  setSearchFilter = flow(function*(filters) {
    this.filter = {
      ...this.filter,
      ...filters,
    };
    if (!this.filter.categoryId) {
      this.filter.categoryId = null;
    }
    if (!this.filter.shouldRead) {
      delete this.filter.shouldRead;
    }
    yield this.fetchBooks();
  });

  changePage = flow(function*(newPage) {
    if (newPage === this.page) {
      return;
    }
    this.page = newPage;
    yield this.fetchBooks();
  });
}

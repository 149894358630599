import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, CardContent, TextField, Box, Button, makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { BasicForm, FormField } from 'src/components/form';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';

const formValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(255)
    .required('Name is required'),
  description: Yup.string().required('Description is required'),
});

const useStyles = makeStyles(them => ({
  buttonCancel: {
    marginRight: them.spacing(2),
  },
}));

const AuthorForm = observer(props => {
  const classer = useStyles();
  const { author } = useStore();
  const { editId } = props;
  const { editingAuthor } = author;
  const [initialValue, setInitialValue] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    if (editId) {
      author.fetchAuthor(editId);
    }
  }, [editId, author]);

  const handleSave = useCallback(
    values => {
      if (editId) {
        author.updateAuthor(editId, values);
      } else {
        author.createAuthor(values);
      }
    },
    [author, editId]
  );
  useEffect(() => {
    if (editId && editingAuthor.id) {
      setInitialValue({
        name: editingAuthor.name,
        description: editingAuthor.description,
      });
    } else if (!editId) {
      setInitialValue({
        name: '',
        description: '',
      });
    }
  }, [editId, editingAuthor.id]);
  return (
    <BasicForm
      title="Author"
      subheader={`${editId ? 'Edit author' : 'Create new author'}`}
      initialValues={initialValue}
      validationSchema={formValidationSchema}
      enableReinitialize
      onSubmit={handleSave}
    >
      <CardContent>
        <FormField
          component={TextField}
          fullWidth
          label="Name"
          margin="normal"
          name="name"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          label="Description"
          margin="normal"
          name="description"
          variant="outlined"
          multiline
          rows={8}
        />
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          variant="contained"
          component={NavLink}
          to="/app/authors"
          className={classer.buttonCancel}
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" variant="contained">
          Save
        </Button>
      </Box>
    </BasicForm>
  );
});

export default AuthorForm;

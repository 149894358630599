import React from 'react';
import Page from 'src/components/Page';
import { Container } from '@material-ui/core';
import * as breadcrumbs from 'src/utils/breakcrumbs';
import NewsletterList from './lisview/NewsletterList';

const pageBreadcrumbs = breadcrumbs.buildWithDashboard([
  {
    label: 'Newsletters',
  },
]);
const NewsletterListView = () => {
  return (
    <Page title="Newsletters" breadcrumbs={pageBreadcrumbs}>
      <Container maxWidth={false}>
        <NewsletterList />
      </Container>
    </Page>
  );
};

export default NewsletterListView;

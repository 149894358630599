import * as paginationUtils from 'src/utils/pagination';

export class NewsletterService {
  _apiService;

  constructor(_apiService) {
    this._apiService = _apiService;
  }

  async fetchNewsletters(fillter, page, pageSize) {
    let newsletterEmails = [];
    let total = 0;
    let p = page || 1;
    const data = await this._apiService.get('newsletterEmails', {
      param: {
        ...fillter,
        ...paginationUtils.getPaginationFilter(p, pageSize),
      },
    });
    total = data.total;
    newsletterEmails = [...newsletterEmails, ...data.data];
    p++;
    return {
      total,
      newsletters: newsletterEmails,
    };
  }

  async deleteNewsletter(newsletterId) {
    return this._apiService.delete(`newsletterEmails/${newsletterId}`);
  }
}

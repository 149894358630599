import { makeAutoObservable } from 'mobx';

export class RouteStore {
  path = '';

  options = null;

  constructor() {
    makeAutoObservable(this);
  }

  setRoute(path, options = null) {
    this.path = path;
    this.options = options;
  }
}

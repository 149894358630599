import * as paginationUtils from 'src/utils/pagination';
import * as formUtils from 'src/utils/form';

const booksBaseUrl = 'crawledBooks';

export class CrawledBookService {
  _apiService;

  constructor(apiService) {
    this._apiService = apiService;
  }

  async fetchBooks(filter, page, pageSize) {
    let books = [];
    let total = 0;
    const p = page || 1;
    const data = await this._apiService.get(booksBaseUrl, {
      params: {
        ...filter,
        ...paginationUtils.getPaginationFilter(p, pageSize),
      },
    });
    total = data.total;
    books = [...books, ...data.data];

    return { total, books };
  }

  fetchBook(bookId) {
    return this._apiService.get(`${booksBaseUrl}/${bookId}`);
  }

  deleteBook(bookId) {
    return this._apiService.delete(`${booksBaseUrl}/${bookId}`);
  }

  updateBook(id, bookFormData) {
    const data = {
      ...bookFormData,
      editedCategoryId: bookFormData.editedCategoryId || 0,
      editedAuthorId: bookFormData.editedAuthorId || 0,
      editedBookFileDeleted: typeof bookFormData.editedBookFile === 'undefined',
      editedImageDeleted: typeof bookFormData.editedImage === 'undefined',
    };
    if (typeof data.editedImage === 'string') {
      data.editedImage = null;
    }
    if (typeof data.editedBookFile === 'string') {
      data.editedBookFile = null;
    }
    const formData = formUtils.makeFormData(data);
    return this._apiService.put(`${booksBaseUrl}/${id}`, {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Page = forwardRef(({ children, className, breadcrumbs, title = '', ...rest }, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={clsx(classes.root, className)} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  breadcrumbs: PropTypes.array,
};

export default Page;

import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import config from '../../../config/config';

const UserList = observer(() => {
  const { user } = useStore();
  const { users, total, page } = user;
  useEffect(() => {
    user.fetchUsers();
  }, [user]);
  const handlePageChange = (_, newPage) => {
    user.changePage(newPage + 1);
  };

  return (
    <Box mt={3}>
      <Card>
        <PerfectScrollbar>
          <Box minWidth={300}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Provider</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(userItem => {
                  return (
                    <>
                      <TableRow key={`cat ${userItem.id}`}>
                        <TableCell>{userItem.name}</TableCell>
                        <TableCell>{userItem.email}</TableCell>
                        <TableCell>{userItem.provider}</TableCell>
                        <TableCell>
                          {moment(userItem.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton component={NavLink} to={`/app/users/views/${userItem.id}`}>
                            <VisibilityIcon size="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[]}
          page={page - 1}
          onChangePage={handlePageChange}
          rowsPerPage={config.itemsPerPage}
        />
      </Card>
    </Box>
  );
});
UserList.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default UserList;

import React from 'react';
import Page from 'src/components/Page';
import { Container } from '@material-ui/core';
import * as breadcrumbs from 'src/utils/breakcrumbs';

import AuthorList from './listview/AuthorList';
import Toolbar from './listview/Toolbar';

const pageBreadcrumbs = breadcrumbs.buildWithDashboard([
  {
    label: 'Authors',
  },
]);
const AuthorListView = () => {
  return (
    <Page title="Authors" breadcrumbs={pageBreadcrumbs}>
      <Container maxWidth={false}>
        <Toolbar />
        <AuthorList />
      </Container>
    </Page>
  );
};
export default AuthorListView;

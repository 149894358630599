import React, { useCallback, useMemo } from 'react';
import { FormControl, FormLabel, FormHelperText } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const FileUploadField = props => {
  const { label, filesLimit, ...rest } = props;
  const [field, meta, helper] = useField(rest);
  const initialFiles = useMemo(() => {
    const val = meta.initialValue;
    return val ? (Array.isArray(val) ? val : [val]) : [];
  }, [meta.initialValue]);
  const handleChange = useCallback(
    files => {
      helper.setValue(filesLimit === 1 ? files?.[0] : files);
    },
    [helper.setValue, filesLimit]
  );
  const isError = useMemo(() => Boolean(meta.touched && meta.error), [meta.touched, meta.error]);
  if (typeof meta.initialValue === 'undefined') {
    return null;
  }
  return (
    <FormControl fullWidth margin="normal">
      <FormLabel error={isError}>{label}</FormLabel>
      <DropzoneArea
        maxFileSize={150000000}
        initialFiles={initialFiles}
        filesLimit={filesLimit}
        {...rest}
        {...field}
        onChange={handleChange}
      />
      {isError && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

FileUploadField.propTypes = {
  label: PropTypes.string.isRequired,
  initialFiles: PropTypes.array,
  filesLimit: PropTypes.number,
};

export default FileUploadField;

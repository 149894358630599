import { makeAutoObservable, flow } from 'mobx';
import { crawledBookService } from '../services';

const defaultFilter = {
  name: null,
  categoryId: null,
  status: 0,
};
export class CrawledBookStore {
  loading;

  error;

  books = [];

  total = 0;

  editingBook = {};

  page = 1;

  filter = defaultFilter;

  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  resetFilter() {
    this.filter = defaultFilter;
  }

  fetchBooks = flow(function*() {
    this.editingBook = {};
    this.loading = true;
    try {
      const result = yield crawledBookService.fetchBooks(this.filter, this.page);
      this.books = result.books;
      this.total = result.total;
    } catch (err) {
      console.error(err);
      this.books = [];
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  fetchBook = flow(function*(bookId) {
    this.editingBook = {};
    try {
      this.loading = true;
      this.editingBook = yield crawledBookService.fetchBook(bookId);
    } catch (err) {
      console.log('Fetch crawled book error', err);
      this.editingBook = {};
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  updateBook = flow(function*(id, bookFormData, onSuccess) {
    let success = false;
    let response;
    try {
      this.loading = true;
      response = yield crawledBookService.updateBook(id, bookFormData);
      this.rootStore.alert.setSuccessMessage('Book data saved');
      success = true;
    } catch (err) {
      console.log('Update crawled book error', err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
      if (success) {
        if (onSuccess) {
          onSuccess(response);
        }
      }
    }
  });

  deleteBook = flow(function*(bookId) {
    this.loading = true;
    try {
      const result = yield crawledBookService.deleteBook(bookId);
      if (result.ok) {
        yield this.fetchBooks();
        this.rootStore.alert.setSuccessMessage('Book has been removed');
      } else {
        this.rootStore.alert.setErrorMessage('Failed to remove book');
      }
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  setSearchFilter = flow(function*(nameSearch, categoryId, status) {
    this.filter.name = nameSearch;
    this.filter.categoryId = categoryId || null;
    this.filter.status = typeof status !== 'undefined' && status !== null ? status : 0;
    yield this.fetchBooks();
  });

  changePage = flow(function*(newPage) {
    if (newPage === this.page) {
      return;
    }
    this.page = newPage;
    yield this.fetchBooks();
  });
}

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import config from '../../../config/config';
import ConfirmDialog from '../../../components/dialog/ConfirmDialog';

const CategoryList = observer(() => {
  const { category } = useStore();
  const { categories, total, page } = category;

  useEffect(() => {
    category.fetchCategories();
  }, [category]);

  const handlePageChange = (_, newPage) => {
    category.changePage(newPage + 1);
  };
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const deletingCategoryIdRef = useRef(null);

  const handleDeleteClick = id => {
    deletingCategoryIdRef.current = id;
    setOpenDialogDelete(true);
  };

  const handleCancelDelete = () => {
    setOpenDialogDelete(false);
  };
  const handleSubmitDelete = useCallback(() => {
    category.delete(deletingCategoryIdRef.current);
    setOpenDialogDelete(false);
  }, [category]);

  return (
    <Box mt={3}>
      <Card>
        <PerfectScrollbar>
          <Box minWidth={300}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Parent</TableCell>
                  <TableCell>Visible</TableCell>
                  <TableCell>Visible In New</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map(cat => {
                  return (
                    <>
                      <TableRow key={`cat ${cat.id}`}>
                        <TableCell>{cat.name}</TableCell>
                        <TableCell>{cat.parent?.name}</TableCell>
                        <TableCell>{cat.isHidden ? 'No' : 'Yes'}</TableCell>
                        <TableCell>{cat.isHiddenInNew ? 'No' : 'Yes'}</TableCell>
                        <TableCell align="right">
                          <IconButton component={NavLink} to={`/app/categories/edit/${cat.id}`}>
                            <EditIcon size="small" />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteClick(cat.id)}>
                            <DeleteIcon size="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[]}
          page={page - 1}
          onChangePage={handlePageChange}
          rowsPerPage={config.itemsPerPage}
        />
      </Card>
      <ConfirmDialog
        title="Delete Confirmation"
        message="Are you sure want to delete category?"
        open={openDialogDelete}
        onClose={handleCancelDelete}
        onOk={handleSubmitDelete}
      />
    </Box>
  );
});

CategoryList.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default CategoryList;

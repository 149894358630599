import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store/store';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import { BasicForm, FormField, CategoryDropdown } from 'src/components/form';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles(() => ({
  box: {
    width: '26%',
  },
  formOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Toolbar = observer(() => {
  const classes = useStyles();
  const { crawledBook } = useStore();
  const { filter } = crawledBook;
  const handleSave = useCallback(values => {
    crawledBook.changePage(1);
    crawledBook.setSearchFilter(values.name, values.categoryId, values.status);
  });
  return (
    <>
      <BasicForm
        initialValues={{
          name: filter.name,
          categoryId: filter.categoryId,
          status: filter.status,
        }}
        enableReinitialize
        onSubmit={handleSave}
        className="form-align"
      >
        <Card>
          <CardContent className={classes.formOption}>
            <Box maxWidth={500}>
              <FormField
                component={TextField}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
                variant="outlined"
                name="name"
                label="Search"
                margin="normal"
              />
            </Box>
            <Box className={classes.box}>
              <CategoryDropdown />
            </Box>
            <Box>
              <FormField
                component={TextField}
                fullWidth
                select
                label="Status"
                margin="normal"
                name="status"
                variant="outlined"
              >
                <MenuItem value="0">Unapproved</MenuItem>
                <MenuItem value="1">Approved</MenuItem>
                <MenuItem value="2">Rejected</MenuItem>
              </FormField>
            </Box>
            <Button type="submit" color="primary" variant="contained">
              Search
            </Button>
          </CardContent>
        </Card>
      </BasicForm>
    </>
  );
});

export default Toolbar;

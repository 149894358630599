import React from 'react';
import { useFormikContext } from 'formik';
import { FileUploadField } from 'src/components/form';

const EbookFileField = props => {
  const { name } = props;
  const { values } = useFormikContext();

  if (values?.audioBook) {
    return null;
  }
  return <FileUploadField label="Ebook" name={name} filesLimit={1} />;
};

export default EbookFileField;

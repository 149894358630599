import React, { useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import config from '../../../config/config';
import ConfirmDialog from '../../../components/dialog/ConfirmDialog';

const AuthorList = observer(() => {
  const { author } = useStore();
  const { authors, total, page } = author;
  useEffect(() => {
    author.fetchAuthors();
  }, [author]);
  const handlePageChange = (_, newPage) => {
    author.changePage(newPage + 1);
  };
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const deletingAuthorIdRef = useRef(null);

  const handleDeleteClick = id => {
    deletingAuthorIdRef.current = id;
    setOpenDialogDelete(true);
  };

  const handleCancelDelete = () => {
    setOpenDialogDelete(false);
  };
  const handleSubmitDelete = useCallback(() => {
    author.delete(deletingAuthorIdRef.current);
    setOpenDialogDelete(false);
  }, [author]);
  return (
    <Box mt={3}>
      <Card>
        <PerfectScrollbar>
          <Box minWidth={300}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>

                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {authors.map(auth => {
                  return (
                    <>
                      <TableRow key={`cat ${auth.id}`}>
                        <TableCell>{auth.name}</TableCell>
                        <TableCell align="right">
                          <IconButton component={NavLink} to={`/app/authors/edit/${auth.id}`}>
                            <EditIcon size="small" />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteClick(auth.id)}>
                            <DeleteIcon size="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[]}
          page={page - 1}
          onChangePage={handlePageChange}
          rowsPerPage={config.itemsPerPage}
        />
      </Card>
      <ConfirmDialog
        title="Delete Confirmation"
        message="Are you sure want to delete author?"
        open={openDialogDelete}
        onClose={handleCancelDelete}
        onOk={handleSubmitDelete}
      />
    </Box>
  );
});
AuthorList.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default AuthorList;

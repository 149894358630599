import React, { useEffect, useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store/store';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useField } from 'formik';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import PropTypes from 'prop-types';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const AuthorDropdown = observer(props => {
  const classes = useStyles();
  const { data, name, label, ...rest } = props;
  const [field, meta] = useField({ name, label });
  const { author } = useStore();
  const { authors } = author;
  useEffect(() => {
    if (!data) {
      author.setFilter({ name: null, all: true });
      author.fetchAuthors('name asc');
    }
    return undefined;
  }, [author, data]);
  const authorData = useMemo(() => {
    return data || authors;
  }, [data, authors]);
  const authorMap = useMemo(() => {
    const m = {};
    if (authorData) {
      authorData.forEach(a => {
        m[a.id] = a;
      });
    }
    return m;
  }, [authorData]);

  const optionLabelRender = useCallback(
    option => {
      if (!option.id) {
        return authorMap[option]?.name || '';
      }
      return option?.name || '';
    },
    [authorMap]
  );

  const inputRenderer = useCallback(
    params => {
      return (
        <TextField
          {...params}
          variant="outlined"
          label={label || 'Author'}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error}
        />
      );
    },
    [meta, label]
  );

  const handleChange = useCallback(
    (e, v) => {
      if (v) {
        field.onChange({
          ...e,
          target: {
            value: v.id,
            name,
          },
        });
      }
    },
    [field]
  );

  useEffect(() => {
    return () => {
      author.resetFilter();
    };
  }, []);

  return (
    <Autocomplete
      fullWidth
      select
      label={label || 'Author'}
      margin="normal"
      name={name}
      variant="outlined"
      options={authorData}
      classes={classes}
      ListboxComponent={ListboxComponent}
      disableListWrap
      getOptionLabel={optionLabelRender}
      getOptionSelected={(option, val) => option.id === val}
      renderInput={inputRenderer}
      {...rest}
      {...field}
      onChange={handleChange}
    />
  );
});

export default AuthorDropdown;

import React, { useCallback } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, TextField, Typography, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStore } from 'src/store/store';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const SubmitButton = observer(() => {
  const { auth } = useStore();
  return (
    <Button
      color="primary"
      disabled={auth.loading}
      fullWidth
      size="large"
      type="submit"
      variant="contained"
    >
      Sign in now
    </Button>
  );
});

const LoginForm = observer(
  ({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => {
    return (
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <Typography color="textPrimary" variant="h2">
            Sign in
          </Typography>
        </Box>

        <TextField
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={touched.email && errors.email}
          label="Email Address"
          margin="normal"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          type="email"
          value={values?.email}
          variant="outlined"
        />
        <TextField
          error={Boolean(touched.password && errors.password)}
          fullWidth
          helperText={touched.password && errors.password}
          label="Password"
          margin="normal"
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          value={values?.password}
          variant="outlined"
        />
        <Box my={2}>
          <SubmitButton />
        </Box>
      </form>
    );
  }
);

const LoginView = observer(() => {
  const classes = useStyles();
  const { auth } = useStore();

  const handleLogin = useCallback(
    values => {
      const { email, password } = values;
      auth.login(email, password);
    },
    [auth]
  );

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required'),
            })}
            onSubmit={handleLogin}
            component={LoginForm}
          />
        </Container>
      </Box>
    </Page>
  );
});

export default LoginView;

import { makeAutoObservable, flow } from 'mobx';
import { newletterService } from 'src/services';

const defaultFilter = {
  email: null,
  all: false,
};
export class NewsletterEmailsStore {
  loading;

  newsletterEmails = [];

  page = 1;

  rootStore;

  filter = defaultFilter;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  fetchNewsletterEmails = flow(function*() {
    this.loading = true;
    try {
      const result = yield newletterService.fetchNewsletters(this.filter, this.page);

      this.newsletterEmails = result.newsletters;
      this.total = result.total;
    } catch (err) {
      console.log('TCL: NewsletterEmailsStore -> fetchNewsletterEmails -> err', err);

      this.err = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  deleteNewsletterEmail = flow(function*(newsletterId) {
    this.loading = true;
    try {
      const result = yield newletterService.deleteNewsletter(newsletterId);
      if (result.ok) {
        yield this.fetchNewsletterEmails();
        this.rootStore.alert.setSuccessMessage('NewletterEmail has been removed');
      } else {
        this.rootStore.alert.setErrorMessage('Failed to remove NewletterEmail ');
      }
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  changePage = flow(function*(newPage) {
    if (newPage === this.page) {
      return;
    }
    this.page = newPage;
    yield this.fetchNewsletterEmails();
  });
}

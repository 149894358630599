import React, { useMemo } from 'react';
import Page from 'src/components/Page';
import { Box, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as breadcrumbs from 'src/utils/breakcrumbs';
import { useParams } from 'react-router-dom';
import AuthorForm from './form/AuthorForm';

const AuthorFormView = ({ title }) => {
  const { id } = useParams();
  const pageBreadcrumbs = useMemo(
    () =>
      breadcrumbs.buildWithDashboard([
        {
          link: '/app/authors',
          label: 'Authors',
        },
        {
          label: `${id ? 'Edit' : 'Create'} Author`,
        },
      ]),
    []
  );

  return (
    <Page title={title} breadcrumbs={pageBreadcrumbs}>
      <Container maxWidth="lg">
        <Box>
          <AuthorForm editId={id} />
        </Box>
      </Container>
    </Page>
  );
};

AuthorFormView.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AuthorFormView;

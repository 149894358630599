import { makeAutoObservable, flow } from 'mobx';
import _ from 'lodash';

import { categoryService } from '../services';

const defaultFilter = {
  name: null,
  all: false,
  showHidden: true,
};

export class CategoryStore {
  loading;

  categories = [];

  rootCategories = [];

  rootStore;

  page = 1;

  filter = defaultFilter;

  total = 0;

  editingCategory = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  resetFilter() {
    this.filter = defaultFilter;
  }

  fetchCategories = flow(function*() {
    this.loading = true;
    try {
      const result = yield categoryService.fetchCategories(this.filter, this.page);
      this.categories = result.categories;
      this.total = result.total;
    } catch (err) {
      console.error(err);
      this.categories = [];
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  fetchRootCategories = flow(function*() {
    this.loading = true;
    try {
      const result = yield categoryService.fetchCategories({ onlyRoot: true });
      this.rootCategories = result.categories;
    } catch (err) {
      console.error(err);
      this.rootCategorie = [];
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  fetchCategory = flow(function*(categoryId) {
    this.loading = true;
    try {
      const category = yield categoryService.fetchCategory(categoryId);
      this.editingCategory = category || {};
    } catch (err) {
      console.error(err);
      this.error = err;
      this.editingCategory = {};
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  create = flow(function*(data) {
    this.loading = true;
    try {
      yield categoryService.createCategory(data);

      this.rootStore.alert.setSuccessMessage('Category data saved');
      this.rootStore.route.setRoute('/app/categories', { replace: true });
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  update = flow(function*(id, data) {
    this.loading = true;
    try {
      yield categoryService.updateCategory(id, data);
      this.rootStore.alert.setSuccessMessage('Category data saved');
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  delete = flow(function*(id) {
    this.loading = true;
    try {
      const result = yield categoryService.deleteCategory(id);
      if (result.ok) {
        yield this.fetchCategories();
        this.rootStore.alert.setSuccessMessage('Category has been removed');
      } else {
        this.rootStore.alert.setErrorMessage('Failed to remove category');
      }
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  changePage = flow(function*(newPage) {
    if (newPage === this.page) {
      return;
    }
    this.page = newPage;
    yield this.fetchCategories();
  });

  setFilter({ name, all }) {
    this.filter = {
      ...defaultFilter,
      name,
      all,
    };
  }

  get categoriesWithParentName() {
    if (!this.categories) {
      return [];
    }
    return _.orderBy(
      this.categories.map(cat => ({
        ...cat,
        name: cat.parent ? `${cat.parent.name} > ${cat.name}` : cat.name,
      })),
      'name'
    );
  }
}

import React from 'react';
import Page from 'src/components/Page';
import { Container } from '@material-ui/core';
import * as breadcrumbs from 'src/utils/breakcrumbs';

import Toolbar from './listview/Toolbar';
import BookList from './listview/BookList';

const pageBreadcrumbs = breadcrumbs.buildWithDashboard([
  {
    label: 'Books',
  },
]);

const BookListView = () => {
  return (
    <Page title="Books" breadcrumbs={pageBreadcrumbs}>
      <Container maxWidth={false}>
        <Toolbar />
        <BookList />
      </Container>
    </Page>
  );
};

export default BookListView;

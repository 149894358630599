import * as paginationUtils from 'src/utils/pagination';

export class ContactMessageService {
  _apiService;

  constructor(apiService) {
    this._apiService = apiService;
  }

  async contactMessages(filter, page, pageSize) {
    let contactMessage = [];
    let total = 0;
    let p = page || 1;

    const data = await this._apiService.get('contactMessages', {
      param: {
        ...filter,
        ...paginationUtils.getPaginationFilter(p, pageSize),
      },
    });
    total = data.total;
    contactMessage = [...contactMessage, ...data.data];

    p++;

    return {
      total,
      contactMessages: contactMessage,
    };
  }

  async contactMessage(messageId) {
    return this._apiService.get(`contactMessages/${messageId}`);
  }

  async deleteContactMessage(messageId) {
    return this._apiService.delete(`contactMessages/${messageId}`);
  }
}

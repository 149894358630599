import { makeAutoObservable } from 'mobx';
import { getErrorMessage } from 'src/utils/error';

export class AlertStore {
  successMessage = null;

  errorMessage = null;

  constructor() {
    makeAutoObservable(this);
  }

  get hasMessage() {
    return this.successMessage || this.errorMessage;
  }

  get message() {
    return this.successMessage ? this.successMessage : this.errorMessage;
  }

  get type() {
    return this.successMessage ? 'success' : 'error';
  }

  setSuccessMessage(msg) {
    this.successMessage = msg;
  }

  setErrorMessage(msg) {
    this.errorMessage = msg;
  }

  setError(err) {
    this.errorMessage = getErrorMessage(err);
  }

  reset() {
    this.errorMessage = null;
    this.successMessage = null;
  }
}

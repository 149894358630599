import { makeAutoObservable, reaction } from 'mobx';
import { useContext, createContext } from 'react';

import { AuthStore } from './auth';
import { RouteStore } from './route';
import { AlertStore } from './alert';
import { CategoryStore } from './category';
import { BookStore } from './book';
import { AuthorStore } from './author';
import { UserStore } from './user';
import { CrawledBookStore } from './crawledBook';
import { ContactMessageStore } from './contactMessage';
import { NewsletterEmailsStore } from './newsletter';

const persistingStore = ['auth'];
const localStorageKey = 'persist:ebooks_admin';

class Store {
  route = new RouteStore();

  auth = new AuthStore(this);

  alert = new AlertStore();

  category = new CategoryStore(this);

  book = new BookStore(this);

  author = new AuthorStore(this);

  user = new UserStore(this);

  crawledBook = new CrawledBookStore(this);

  contactMessage = new ContactMessageStore(this);

  newsletterEmail = new NewsletterEmailsStore(this);

  constructor() {
    makeAutoObservable(this, {
      _restoreData: false,
      _persistData: false,
    });
    this._restoreData();
    this._persistData();
  }

  _persistData() {
    if (!persistingStore.length) {
      return;
    }

    reaction(
      () => {
        const data = {};
        persistingStore.forEach(storeName => {
          data[storeName] = this[storeName].serializedData;
        });
        return data;
      },
      data => {
        localStorage.setItem(localStorageKey, JSON.stringify(data));
      }
    );
  }

  _restoreData() {
    const data = localStorage.getItem(localStorageKey);
    if (!data) {
      return;
    }

    const jsonData = JSON.parse(data);
    Object.keys(jsonData).forEach(storeName => {
      this[storeName].restoreData(jsonData[storeName]);
    });
  }
}

export const rootStore = new Store();

const RootStoreContext = createContext(null);
export const StoreProvider = RootStoreContext.Provider;

export const useStore = () => {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
};

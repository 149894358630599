import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, CardContent, TextField, Box, Button, makeStyles } from '@material-ui/core';
import { BasicForm, FormField } from 'src/components/form';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(them => ({
  buttonCancel: {
    marginRight: them.spacing(2),
  },
}));

const ContactForm = observer(props => {
  const classer = useStyles();
  const { contactMessage } = useStore();
  let { editId } = props;
  if (editId) {
    editId = parseInt(editId, 10);
  }
  const { editingContactMessage } = contactMessage;
  useEffect(() => {
    contactMessage.fetchContactMessages();
    if (editId) {
      contactMessage.fetchContactMessage(editId);
    }
  }, [contactMessage, editId]);

  return (
    <BasicForm
      title="View Contact Message"
      initialValues={{
        userId: editingContactMessage.userId ? editingContactMessage.userId : '',
        name: editingContactMessage.userId
          ? editingContactMessage.user.name
          : editingContactMessage.name,
        email: editingContactMessage.userId
          ? editingContactMessage.user.email
          : editingContactMessage.email,
        editedDescription: editingContactMessage.description,
      }}
      enableReinitialize
    >
      <CardContent>
        <FormField
          component={TextField}
          fullWidth
          label="UserID"
          margin="normal"
          name="userId"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          label="Name"
          margin="normal"
          name="name"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          label="Email"
          margin="normal"
          name="email"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          label="Description"
          margin="normal"
          name="editedDescription"
          variant="outlined"
          multiline
          rows={8}
        />
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          variant="contained"
          component={NavLink}
          to="/app/contactMesssages"
          className={classer.buttonCancel}
        >
          Back
        </Button>
      </Box>
    </BasicForm>
  );
});

export default ContactForm;

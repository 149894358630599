import * as paginationUtils from 'src/utils/pagination';
import * as formUtils from 'src/utils/form';
import { BOOK_TYPE_AUDIO } from 'src/constants/book';
import { timeToSeconds } from '../utils/time';

const booksBaseUrl = 'books';

const makeBookSubmitFormData = bookFormData => {
  const data = {
    ...bookFormData,
  };
  if (typeof data.image === 'string') {
    data.image = null;
  }
  if (typeof data.bookFile === 'string') {
    data.bookFile = null;
  }

  if (data.bookType === BOOK_TYPE_AUDIO) {
    data.bookFile = null;
    if (data.audioBookChapters) {
      data.audioBookChapters.forEach(it => {
        it.duration = timeToSeconds(it.duration);
      });
      data.audioBookChapters = JSON.stringify(data.audioBookChapters);
    }
  } else {
    data.audioBookChapters = null;
  }

  console.log({ data });
  const formData = formUtils.makeFormData(data);
  return formData;
};

export class BookService {
  _apiService;

  constructor(apiService) {
    this._apiService = apiService;
  }

  async fetchBooks(filter, page, pageSize) {
    let books = [];
    let total = 0;
    let p = page || 1;
    const fetchAll = filter?.all;
    while (true) {
      const data = await this._apiService.get('books', {
        params: {
          ...filter,
          ...paginationUtils.getPaginationFilter(p, pageSize),
        },
      });
      total = data.total;
      books = [...books, ...data.data];
      if (!fetchAll || books.length >= total) {
        break;
      }
      p++;
    }

    return { total, books };
  }

  createBook(bookFormData) {
    const formData = makeBookSubmitFormData(bookFormData);

    return this._apiService.post(`${booksBaseUrl}`, {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  updateBook(id, bookFormData) {
    const formData = makeBookSubmitFormData(bookFormData);

    return this._apiService.put(`${booksBaseUrl}/${id}`, {
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  fetchBook(bookId) {
    return this._apiService.get(`${booksBaseUrl}/${bookId}`);
  }

  deleteBook(bookId) {
    return this._apiService.delete(`${booksBaseUrl}/${bookId}`);
  }
}

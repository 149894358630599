import React from 'react';
import Page from 'src/components/Page';
import { Container } from '@material-ui/core';
import * as breadcrumbs from 'src/utils/breakcrumbs';
import Toolbar from './listview/Toolbar';
import CategoryList from './listview/CategoryList';

const pageBreadcrumbs = breadcrumbs.buildWithDashboard([
  {
    label: 'Categories',
  },
]);

const CategoryListView = () => {
  return (
    <Page title="Categories" breadcrumbs={pageBreadcrumbs}>
      <Container maxWidth={false}>
        <Toolbar />
        <CategoryList />
      </Container>
    </Page>
  );
};

export default CategoryListView;

import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { StoreProvider, rootStore } from 'src/store/store';
import RouteNavigator from 'src/components/RouteNavigator';
import Alert from 'src/components/Alert';

const App = () => {
  const routing = useRoutes(routes);

  return (
    <StoreProvider value={rootStore}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
        <RouteNavigator />
        <Alert />
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;

import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store/store';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import {
  BasicForm,
  FormField,
  CheckBox,
  LanguageDropdown,
  BookTypeDropdown,
} from 'src/components/form';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles(() => ({
  box: {
    width: '26%',
  },
  formOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionsRow: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiBox-root': {
      marginRight: '10px',
    },
  },
}));

const Toolbar = observer(() => {
  const classes = useStyles();
  const { book, category } = useStore();
  const { categoriesWithParentName } = category;
  const { filter } = book;

  useEffect(() => {
    category.setFilter({ name: null, all: true });
    category.fetchCategories();
  }, [category]);
  const handleSave = useCallback(values => {
    book.changePage(1);
    book.setSearchFilter(values);
  });
  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={3}>
        <Button color="primary" variant="contained" component={NavLink} to="/app/books/create">
          Add book
        </Button>
      </Box>
      <BasicForm
        initialValues={{
          name: filter.name,
          categoryId: filter.categoryId,
          lang: filter.lang,
          bookType: filter.bookType,
          shouldRead: filter.shouldRead,
        }}
        enableReinitialize
        onSubmit={handleSave}
        className="form-align"
      >
        <Card>
          <CardContent className={classes.formOption}>
            <Box maxWidth={500}>
              <FormField
                component={TextField}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
                variant="outlined"
                name="name"
                label="Search"
                margin="normal"
              />
            </Box>
            <Box className={classes.box}>
              <FormField
                component={TextField}
                fullWidth
                select
                label="Categories"
                margin="normal"
                name="categoryId"
                variant="outlined"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {categoriesWithParentName.map(cat => {
                  return (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.name}
                    </MenuItem>
                  );
                })}
              </FormField>
            </Box>

            <CheckBox name="shouldRead" label="Should Read" />

            <Button type="submit" color="primary" variant="contained">
              Search
            </Button>
          </CardContent>

          <CardContent className={classes.optionsRow}>
            <Box className={classes.box}>
              <LanguageDropdown withAll />
            </Box>
            <Box className={classes.box}>
              <BookTypeDropdown withAll />
            </Box>
          </CardContent>
        </Card>
      </BasicForm>
    </>
  );
});

export default Toolbar;

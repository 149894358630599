import * as paginationUtils from 'src/utils/pagination';

export class UserService {
  _apiService;

  constructor(apiService) {
    this._apiService = apiService;
  }

  async fetchUsers(filter, page, pageSize) {
    let users = [];
    let total = 0;
    let p = page || 1;
    const fechAll = filter?.all;
    while (true) {
      const data = await this._apiService.get('users', {
        params: {
          ...filter,
          ...paginationUtils.getPaginationFilter(p, pageSize),
        },
      });
      total = data.total;
      users = [...users, ...data.data];
      if (!fechAll || users.length >= total) {
        break;
      }
      p++;
    }
    return {
      total,
      users,
    };
  }

  async fetchUser(userId) {
    return this._apiService.get(`users/${userId}`);
  }
}

import { makeAutoObservable, flow } from 'mobx';
import { authService, apiService } from '../services';

export class AuthUser {
  id = 0;

  name = '';

  email = null;

  role = 0;

  constructor() {
    makeAutoObservable(this);
  }

  update(data) {
    this.id = data.id;
    this.name = data.name;
    this.role = data.role;
  }

  reset() {
    this.id = 0;
    this.name = '';
    this.role = 0;
  }

  restoreData(data) {
    this.update(data);
  }

  get serializedData() {
    return {
      id: this.id,
      name: this.name,
      role: this.role,
    };
  }
}

export class AuthStore {
  loading = false;

  user = null;

  token = null;

  rootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.user = new AuthUser();
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  login = flow(function*(email, password) {
    this.loading = true;
    try {
      const result = yield authService.login(email, password);
      this.token = result.token;
      this.user.update(result.user);
      apiService.setAuthToken(this.token);
      this.rootStore.route.setRoute('/app/dashboard', { replace: true });
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  logout() {
    this.token = null;
    this.user.reset();
  }

  restoreData(data) {
    this.token = data.token;
    this.user.restoreData(data.user);
    apiService.setAuthToken(this.token);
  }

  get serializedData() {
    return {
      token: this.token,
      user: this.user.serializedData,
    };
  }
}

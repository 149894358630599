import { ApiError } from 'src/services/api';
import { messages } from './messages';

export const getErrorMessage = err => {
  if (err instanceof ApiError) {
    if (err.data.err) {
      const msg = messages[err.data.err.msg];
      return msg || err.data.err.msg;
    }
    if (err.data.validationErrs) {
      const validationErrors = err.data.validationErrs.map(e => `${e.field}: ${e.msg}`);
      return validationErrors.join('\n');
    }
    return JSON.stringify(err);
  }
  return err.message ? err.message : err;
};

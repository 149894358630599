const dashboardItemWithLink = {
  link: '/app',
  label: 'Dashboard',
};
const dashboardItemWithoutLink = {
  label: 'Dashboard',
};

export const buildWithDashboard = breadcrumbs => {
  return [dashboardItemWithLink, ...breadcrumbs];
};

export const resetToDashboard = () => {
  return [dashboardItemWithoutLink];
};

export const timeToSeconds = timeString => {
  const parts = timeString.split(':');
  if (parts.length === 1) {
    return parseInt(parts[0], 10);
  }
  if (parts.length === 2) {
    return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10);
  }
  if (parts.length === 3) {
    return parseInt(parts[0], 10) * 3600 + parseInt(parts[1], 10) * 60 + parseInt(parts[2], 10);
  }
  throw new Error('Invalid time string');
};

export const secondsToTimeString = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secs = seconds - hours * 3600 - minutes * 60;
  let hrsStr = '';
  if (hours > 0) {
    hrsStr = `${hours}`.padStart(2, '0');
  }
  const minsStr = `${minutes}`.padStart(2, '0');
  const secsStr = `${secs}`.padStart(2, '0');
  return `${hrsStr ? `${hrsStr}:` : ''}${minsStr}:${secsStr}`;
};

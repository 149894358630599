import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Box, makeStyles, FormLabel } from '@material-ui/core';

const useStyles = makeStyles(them => ({
  alignLabelcheckbox: {
    marginLeft: them.spacing(2),
  },
}));

const CheckBox = ({ name, label, className }) => {
  const classer = useStyles();
  return (
    <Box className={className} display="flex" alignItems="center">
      <Field type="checkbox" name={name} />
      <FormLabel className={classer.alignLabelcheckbox}>{label}</FormLabel>
    </Box>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.object,
};

export default CheckBox;

import React from 'react';
import Page from 'src/components/Page';
import { Container } from '@material-ui/core';
import * as breadcrumbs from 'src/utils/breakcrumbs';
import Toolbar from './listview/Toolbar';
import UserList from './listview/UserList';

const pageBreadcrumbs = breadcrumbs.buildWithDashboard([
  {
    label: 'Users',
  },
]);
const UserListView = () => {
  return (
    <Page title="Users" breadcrumbs={pageBreadcrumbs}>
      <Container maxWidth={false}>
        <Toolbar />
        <UserList />
      </Container>
    </Page>
  );
};
export default UserListView;

import React, { useMemo } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { FormField } from 'src/components/form';

const bookTypes = [
  {
    id: 'epub',
    name: 'EPUB',
  },
  {
    id: 'pdf',
    name: 'PDF',
  },
];

export default props => {
  const { name, withAll } = props;

  const types = useMemo(() => (withAll ? [{ id: '', name: 'All' }, ...bookTypes] : bookTypes), [
    withAll,
  ]);

  return (
    <FormField
      component={TextField}
      fullWidth
      select
      label="Book Type"
      margin="normal"
      name={name || 'bookType'}
      variant="outlined"
    >
      {types.map(type => {
        return (
          <MenuItem key={type.id} value={type.id}>
            {type.name}
          </MenuItem>
        );
      })}
    </FormField>
  );
};

import React, { useMemo } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { FormField } from 'src/components/form';

const languages = [
  {
    id: 'vi',
    name: 'Vietnamese',
  },
  {
    id: 'en',
    name: 'English',
  },
];

export default props => {
  const { name, withAll } = props;

  const langs = useMemo(() => (withAll ? [{ id: '', name: 'All' }, ...languages] : languages), [
    withAll,
  ]);

  return (
    <FormField
      component={TextField}
      fullWidth
      select
      label="Language"
      margin="normal"
      name={name || 'lang'}
      variant="outlined"
    >
      {langs.map(lang => {
        return (
          <MenuItem key={lang.id} value={lang.id}>
            {lang.name}
          </MenuItem>
        );
      })}
    </FormField>
  );
};

import React, { useEffect, useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '@material-ui/core/IconButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useStore } from 'src/store/store';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import config from '../../../config/config';
import ConfirmDialog from '../../../components/dialog/ConfirmDialog';

const NewsletterList = observer(() => {
  const { newsletterEmail } = useStore();

  const { newsletterEmails, page, total } = newsletterEmail;
  const deletingNewsletterRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    newsletterEmail.fetchNewsletterEmails();
  }, [newsletterEmail]);
  const handlePageChange = useCallback(
    (_, newPage) => {
      newsletterEmail.changePage(newPage + 1);
    },
    [newsletterEmail]
  );
  const handleDeleteClick = useCallback(id => {
    deletingNewsletterRef.current = id;
    setOpenDialog(true);
  });
  const handleCancelDelete = useCallback(() => {
    setOpenDialog(false);
  });
  const handleSubmitDelete = useCallback(() => {
    newsletterEmail.deleteNewsletterEmail(deletingNewsletterRef.current);
    setOpenDialog(false);
  }, [newsletterEmail]);
  return (
    <Box mt={3}>
      <Card>
        <PerfectScrollbar>
          <Box minWidth={300}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {newsletterEmails.map(newLetterItem => {
                  return (
                    <>
                      <TableRow key={`contact ${newLetterItem.id}`}>
                        <TableCell>{newLetterItem.email}</TableCell>

                        <TableCell>
                          {moment(newLetterItem.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton>
                            <DeleteIcon
                              size="small"
                              onClick={() => handleDeleteClick(newLetterItem.id)}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[]}
          page={page - 1}
          onChangePage={handlePageChange}
          rowsPerPage={config.itemsPerPage}
        />
      </Card>
      <ConfirmDialog
        title="Delete Confirmation"
        message="Are you sure want to delete NewsletterEmail ?"
        open={openDialog}
        onClose={handleCancelDelete}
        onOk={handleSubmitDelete}
      />
    </Box>
  );
});

export default NewsletterList;

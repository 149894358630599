import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useStore } from '../store/store';

const RouteNavigator = observer(() => {
  const navigate = useNavigate();
  const { route } = useStore();

  useEffect(() => {
    reaction(
      () => ({ path: route.path, options: route.options }),
      ({ path, options }) => {
        navigate(path, options);
      }
    );
  }, [route, navigate]);

  return null;
});

export default RouteNavigator;

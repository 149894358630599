import React, { useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import config from '../../../config/config';
import ConfirmDialog from '../../../components/dialog/ConfirmDialog';

const BookList = observer(() => {
  const { book } = useStore();
  const { books, total, page } = book;
  useEffect(() => {
    book.fetchBooks();
  }, [book]);

  const handlePageChange = (_, newPage) => {
    book.changePage(newPage + 1);
  };
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const deletingBookIdRef = useRef(null);

  const handleDeleteClick = id => {
    deletingBookIdRef.current = id;
    setOpenDialogDelete(true);
  };

  const handleCancelDelete = () => {
    setOpenDialogDelete(false);
  };
  const handleSubmitDelete = useCallback(() => {
    book.deleteBook(deletingBookIdRef.current);
    setOpenDialogDelete(false);
  }, [book]);

  return (
    <Box mt={3}>
      <Card>
        <PerfectScrollbar>
          <Box minWidth={300}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Book Type</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {books.map(bookItem => {
                  return (
                    <>
                      <TableRow key={`cat ${bookItem.id}`}>
                        <TableCell>{bookItem.name}</TableCell>
                        {bookItem.category.parent !== null ? (
                          <TableCell>{`${bookItem.category.parent.name} > ${bookItem.category.name}`}</TableCell>
                        ) : (
                          <TableCell>{bookItem.category.name}</TableCell>
                        )}
                        {bookItem.author !== null ? (
                          <TableCell>{bookItem.author.name}</TableCell>
                        ) : (
                          <TableCell />
                        )}
                        <TableCell>{bookItem.bookType}</TableCell>
                        <TableCell>{bookItem.lang}</TableCell>
                        <TableCell align="right">
                          <IconButton component={NavLink} to={`/app/books/edit/${bookItem.id}`}>
                            <EditIcon size="small" />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon
                              size="small"
                              onClick={() => handleDeleteClick(bookItem.id)}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[]}
          page={page - 1}
          onChangePage={handlePageChange}
          rowsPerPage={config.itemsPerPage}
        />
      </Card>
      <ConfirmDialog
        title="Delete Confirmation"
        message="Are you sure want to delete Book?"
        open={openDialogDelete}
        onClose={handleCancelDelete}
        onOk={handleSubmitDelete}
      />
    </Box>
  );
});

BookList.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

export default BookList;

import React, { useMemo } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Button, TextField, makeStyles } from '@material-ui/core';
import { FormField } from 'src/components/form';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(1),
    border: '1px dashed grey',
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: '',
  },
  row: {
    marginTop: theme.spacing(2),
  },
  addBtn: {
    marginTop: theme.spacing(2),
  },
  removeBtn: {
    marginTop: theme.spacing(1),
  },
  chapterHeading: {
    fontWeight: 'bold',
  },
}));

const AudioChapterInput = props => {
  const classer = useStyles();
  const { values } = useFormikContext();
  const { name } = props;
  const data = useMemo(() => values?.[name], [values, name]);

  if (!values?.audioBook) {
    return null;
  }

  return (
    <FieldArray name={name}>
      {({ remove, push }) => (
        <div className={classer.root}>
          <div className="MuiFormLabel-root">Audio Book Chapters</div>
          <div className={classer.container}>
            {data?.length > 0 &&
              data.map((chapter, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={classer.row} key={`${chapter.id}_${index}`}>
                  <div className={classer.chapterHeading}>Chapter #{index + 1}</div>
                  <FormField
                    component={TextField}
                    fullWidth
                    label="Title"
                    margin="normal"
                    name={`${name}.${index}.title`}
                    variant="outlined"
                  />
                  <FormField
                    component={TextField}
                    fullWidth
                    label="Audio URL"
                    margin="normal"
                    name={`${name}.${index}.url`}
                    variant="outlined"
                  />
                  <FormField
                    component={TextField}
                    fullWidth
                    label="Duration (s)"
                    margin="normal"
                    name={`${name}.${index}.duration`}
                    variant="outlined"
                  />
                  <Button
                    className={classer.removeBtn}
                    type="button"
                    variant="contained"
                    onClick={() => remove(index)}
                  >
                    Remove Chapter
                  </Button>
                </div>
              ))}

            <Button
              className={classer.addBtn}
              type="button"
              color="primary"
              variant="contained"
              onClick={() => push({ title: '', url: '', duration: 0 })}
            >
              Add New Chapter
            </Button>
          </div>
        </div>
      )}
    </FieldArray>
  );
};

export default AudioChapterInput;

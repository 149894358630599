import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store/store';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  MenuItem,
} from '@material-ui/core';

import { BasicForm, FormField } from 'src/components/form';
import { Search as SearchIcon } from 'react-feather';

const useStyles = makeStyles(() => ({
  box: {
    width: '26%',
  },
  formOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Toolbar = observer(() => {
  const classes = useStyles();
  const { user } = useStore();
  const { filter } = user;

  useEffect(() => {
    user.fetchUsers({ all: true });
  }, [user]);

  const handleSave = useCallback(values => {
    user.changePage(1);
    user.setSearchFilter(values.name, values.email, values.provider);
  });
  return (
    <>
      <BasicForm
        initialValues={{
          name: filter.name,
          email: filter.email,
          provider: filter.provider,
        }}
        enableReinitialize
        onSubmit={handleSave}
        className="form-align"
      >
        <Card>
          <CardContent className={classes.formOption}>
            <Box maxWidth={500}>
              <FormField
                component={TextField}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder="Search"
                variant="outlined"
                name="name"
                label="Search"
                margin="normal"
              />
            </Box>
            <Box maxWidth={500}>
              <FormField
                component={TextField}
                fullWidth
                placeholder="Email"
                variant="outlined"
                name="email"
                label="Email"
                margin="normal"
              />
            </Box>
            <Box className={classes.box}>
              <FormField
                component={TextField}
                fullWidth
                select
                label="Provider"
                margin="normal"
                name="provider"
                variant="outlined"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="email">Email </MenuItem>
                <MenuItem value="google">Google </MenuItem>
                <MenuItem value="facebook">Facebook</MenuItem>
              </FormField>
            </Box>

            <Button type="submit" color="primary" variant="contained">
              Search
            </Button>
          </CardContent>
        </Card>
      </BasicForm>
    </>
  );
});

export default Toolbar;

import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Divider,
  CardContent,
  TextField,
  Box,
  Button,
  MenuItem,
  makeStyles,
  FormLabel,
  Chip,
} from '@material-ui/core';
import * as Yup from 'yup';
import {
  BasicForm,
  FormField,
  FileUploadField,
  AuthorDropdown,
  LanguageDropdown,
} from 'src/components/form';
import { Field } from 'formik';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';
import { BOOK_TYPE_AUDIO } from 'src/constants/book';
import AudioChapterInput from './AudioChapterInput';
import EbookFileField from './EbookFileField';
import { secondsToTimeString } from '../../../utils/time';

const formValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  categoryId: Yup.number().required('Category is required'),
  authorId: Yup.number().required('Author is required'),
  description: Yup.string().required('Description is required'),
});

const useStyles = makeStyles(them => ({
  buttonCancel: {
    marginRight: them.spacing(2),
  },
  alignLabelcheckbox: {
    marginLeft: them.spacing(2),
  },
  chkContainer: {
    marginTop: them.spacing(2),
    marginBottom: them.spacing(2),
  },
}));

const getAudioChapters = book => {
  if (book.bookType === BOOK_TYPE_AUDIO) {
    return book.audioBookChapters?.map(it => ({
      id: it.id,
      title: it.title,
      url: it.bookFile?.uri,
      duration: secondsToTimeString(it.duration),
    }));
  }
  return null;
};

const BookForm = observer(props => {
  const classer = useStyles();
  const { book, category, author } = useStore();
  let { editId } = props;
  if (editId) {
    editId = parseInt(editId, 10);
  }
  const { categoriesWithParentName } = category;
  const { editingBook } = book;
  const [initialValue, setInitialValue] = useState({
    name: '',
    description: '',
    categoryId: '',
    authorId: '',
    lang: 'vi',
    tikiLink: '',
    shopeeLink: '',
    lazadaLink: '',
    audioBook: false,
    shouldRead: false,
    isPremium: false,
    audioBookChapters: null,
  });

  useEffect(() => {
    category.setFilter({ name: null, all: true });
    category.fetchCategories();
  }, [category, author]);

  useEffect(() => {
    if (editId) {
      book.fetchBook(editId);
    }
  }, [editId, book]);

  const handleSave = useCallback(
    values => {
      const data = { ...values };
      const { audioBook } = data;
      delete data.audioBook;

      if (audioBook) {
        data.bookType = BOOK_TYPE_AUDIO;
      }

      if (editId) {
        book.updateBook(editId, data);
      } else {
        book.createBook(data);
      }
    },
    [book, editId]
  );

  useEffect(() => {
    if (editId && editingBook.id) {
      setInitialValue({
        name: editingBook.name,
        categoryId: editingBook.categoryId,
        authorId: editingBook.authorId,
        description: editingBook.description,
        lang: editingBook.lang || 'vi',
        image: editingBook.imageUrl,
        bookFile: editingBook.bookFileUrl,
        tikiLink: editingBook.tikiLink,
        shopeeLink: editingBook.shopeeLink,
        lazadaLink: editingBook.lazadaLink,
        shouldRead: editingBook.shouldRead || false,
        isPremium: editingBook.isPremium || false,
        audioBook: editingBook.bookType === BOOK_TYPE_AUDIO,
        audioBookChapters: getAudioChapters(editingBook),
      });
    } else if (!editId) {
      setInitialValue({
        name: '',
        description: '',
        categoryId: '',
        authorId: '',
        lang: 'vi',
        image: null,
        bookFile: null,
        tikiLink: '',
        shopeeLink: '',
        lazadaLink: '',
        shouldRead: false,
        audioBook: false,
        isPremium: false,
        audioBookChapters: null,
      });
    }
  }, [editId, editingBook.id]);

  return (
    <BasicForm
      title="Book"
      subheader={`${editId ? 'Edit Book' : 'Create Book'}`}
      initialValues={initialValue}
      validationSchema={formValidationSchema}
      enableReinitialize
      onSubmit={handleSave}
    >
      <CardContent>
        <FormField
          component={TextField}
          fullWidth
          label="Name"
          margin="normal"
          name="name"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          select
          label="Category"
          margin="normal"
          name="categoryId"
          variant="outlined"
        >
          {categoriesWithParentName.map(cat => {
            return (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            );
          })}
        </FormField>

        <AuthorDropdown name="authorId" label="Author" />

        <LanguageDropdown />

        <FormField
          component={TextField}
          fullWidth
          label="Description"
          margin="normal"
          name="description"
          variant="outlined"
          multiline
          rows={8}
        />
        <Box display="flex" alignItems="center" className={classer.chkContainer}>
          <Field type="checkbox" name="audioBook" />
          <FormLabel className={classer.alignLabelcheckbox}>Audio Book</FormLabel>
        </Box>
        <FormField
          component={TextField}
          fullWidth
          label="Tiki Affiliate Link"
          margin="normal"
          name="tikiLink"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          label="Shopee Affiliate Link"
          margin="normal"
          name="shopeeLink"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          label="Lazada Affiliate Link"
          margin="normal"
          name="lazadaLink"
          variant="outlined"
        />
        {(!editId || editingBook.id) && (
          <FileUploadField label="Image" name="image" filesLimit={1} />
        )}
        {(!editId || editingBook.id) && <EbookFileField name="bookFile" />}
        <Chip label={`Type: ${editingBook.bookType || 'unset'}`} style={{ marginBottom: '10px' }} />

        <AudioChapterInput name="audioBookChapters" />

        <Box display="flex" alignItems="center" className={classer.chkContainer}>
          <Field type="checkbox" name="isPremium" />
          <FormLabel className={classer.alignLabelcheckbox}>Premium Book</FormLabel>
        </Box>

        <Box display="flex" alignItems="center" className={classer.chkContainer}>
          <Field type="checkbox" name="shouldRead" />
          <FormLabel className={classer.alignLabelcheckbox}>Should Read</FormLabel>
        </Box>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          variant="contained"
          component={NavLink}
          to="/app/books"
          className={classer.buttonCancel}
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" variant="contained" disabled={book.loading}>
          Save
        </Button>
      </Box>
    </BasicForm>
  );
});

export default BookForm;

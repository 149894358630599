import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const FormField = ({ component, children, ...rest }) => {
  const [field, meta] = useField(rest);

  const Component = useMemo(() => component, [component]);

  return (
    <Component
      {...rest}
      {...field}
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error}
    >
      {children}
    </Component>
  );
};

FormField.propTypes = {
  component: PropTypes.elementType.isRequired,
  children: PropTypes.element,
};

export default FormField;

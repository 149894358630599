import React from 'react';
import { Card, CardHeader, Divider } from '@material-ui/core';
import { Formik, Form } from 'formik';
import PropsTypes from 'prop-types';

const FormComponent = ({ subheader, title, children }) => {
  return (
    <Form>
      <Card>
        <CardHeader subheader={subheader} title={title} />
        <Divider />
        {children}
      </Card>
    </Form>
  );
};

FormComponent.propTypes = {
  subheader: PropsTypes.string.isRequired,
  title: PropsTypes.string.isRequired,
  children: PropsTypes.element,
};

const BasicForm = ({ subheader, title, children, ...rest }) => {
  return (
    <Formik {...rest}>
      <FormComponent subheader={subheader} title={title}>
        {children}
      </FormComponent>
    </Formik>
  );
};

BasicForm.propTypes = {
  subheader: PropsTypes.string.isRequired,
  title: PropsTypes.string.isRequired,
  children: PropsTypes.element,
};

export default BasicForm;

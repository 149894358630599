import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Divider, CardContent, TextField, Box, Button, makeStyles } from '@material-ui/core';
import { BasicForm, FormField } from 'src/components/form';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(them => ({
  buttonCancel: {
    marginRight: them.spacing(2),
  },
}));

const UserForm = observer(props => {
  const classer = useStyles();
  const { user } = useStore();
  let { editId } = props;
  if (editId) {
    editId = parseInt(editId, 10);
  }
  const { editingUser } = user;
  useEffect(() => {
    user.fetchUsers();
    if (editId) {
      user.fetchUser(editId);
    }
  }, [user, editId]);

  return (
    <BasicForm
      title="View User"
      subheader="View User"
      initialValues={{
        name: editingUser.name,
        email: editingUser.email,
        provider: editingUser.provider,
      }}
      enableReinitialize
    >
      <CardContent>
        <FormField
          component={TextField}
          fullWidth
          label="Name"
          margin="normal"
          name="name"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          label="Email"
          margin="normal"
          name="email"
          variant="outlined"
        />
        <FormField
          component={TextField}
          fullWidth
          label="Provider"
          margin="normal"
          name="provider"
          variant="outlined"
        />
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          variant="contained"
          component={NavLink}
          to="/app/users"
          className={classer.buttonCancel}
        >
          Back User
        </Button>
      </Box>
    </BasicForm>
  );
});

export default UserForm;

import React from 'react';
import { Breadcrumbs, Link, Typography, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}));

const Item = ({ link, label }) => {
  if (link) {
    return (
      <Link color="inherit" component={NavLink} to={link}>
        {label}
      </Link>
    );
  }
  return <Typography color="textPrimary">{label}</Typography>;
};

Item.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
};

const BreadcrumbsComponent = ({ breadcrumbs }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs
      className={classes.root}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbs?.map(item => (
        <Item link={item.link} label={item.label} />
      ))}
    </Breadcrumbs>
  );
};

BreadcrumbsComponent.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default BreadcrumbsComponent;

import config from 'src/config/config';

export const getPaginationFilter = (page = 1, pageSize = config.itemsPerPage) => {
  return {
    page,
    pageSize,
  };
};

export const checkCanGoNextPage = (total, page = 1, pageSize = config.itemsPerPage) => {
  return page * pageSize < total;
};

export class AuthService {
  _apiService;

  constructor(apiService) {
    this._apiService = apiService;
  }

  async login(email, password) {
    return this._apiService.post('auth/login', {
      data: {
        email,
        password,
      },
    });
  }
}

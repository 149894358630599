import React from 'react';
import Page from 'src/components/Page';
import { Container } from '@material-ui/core';
import * as breadcrumbs from 'src/utils/breakcrumbs';
import ContactMessageList from './listview/ContactMessageList';

const pageBreadcrumbs = breadcrumbs.buildWithDashboard([
  {
    label: ' Contact Messages',
  },
]);
const ContactMessageListView = () => {
  return (
    <Page title="Contact Messages" breadcrumbs={pageBreadcrumbs}>
      <Container maxWidth={false}>
        <ContactMessageList />
      </Container>
    </Page>
  );
};

export default ContactMessageListView;

import { ApiService } from './api';
import { AuthService } from './auth';
import { CategoryService } from './category';
import { BookService } from './book';
import { AuthorService } from './author';
import { UserService } from './user';
import { CrawledBookService } from './crawledBook';
import { ContactMessageService } from './contactMessage';
import { NewsletterService } from './newsletter';

export const apiService = new ApiService();
export const authService = new AuthService(apiService);
export const categoryService = new CategoryService(apiService);
export const bookService = new BookService(apiService);
export const authorSevice = new AuthorService(apiService);
export const userService = new UserService(apiService);
export const crawledBookService = new CrawledBookService(apiService);
export const contactMessageService = new ContactMessageService(apiService);
export const newletterService = new NewsletterService(apiService);

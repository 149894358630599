import { makeAutoObservable, flow } from 'mobx';

import { authorSevice } from '../services';

const defaultFilter = {
  name: null,
  all: false,
};
export class AuthorStore {
  loading;

  authors = [];

  page = 1;

  rootAuthors = [];

  rootStore;

  editingAuthor = {};

  filter = defaultFilter;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  resetFilter() {
    this.filter = defaultFilter;
    this.authors = [];
  }

  /**
   * sort: string, ie: "name asc, id asc"
   */
  fetchAuthors = flow(function*(sort) {
    this.loading = true;
    try {
      const filters = {
        ...this.filter,
      };
      if (sort) {
        filters.sort = sort;
      }
      const reusult = yield authorSevice.fetchAuthors(filters, this.page);
      this.authors = reusult.authors;
      this.total = reusult.total;
    } catch (err) {
      console.log('TCL: AuthorStore -> fetchAuthor -> err', err);
      this.err = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  fetchAuthor = flow(function*(authorId) {
    this.loading = true;
    try {
      const author = yield authorSevice.fetchAuthor(authorId);
      this.editingAuthor = author || {};
    } catch (err) {
      console.error(err);
      this.error = err;
      this.editingAuthor = {};
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  createAuthor = flow(function*(data) {
    this.loading = true;
    try {
      yield authorSevice.createAuthor(data);

      this.rootStore.alert.setSuccessMessage('Author data saved');
      this.rootStore.route.setRoute('/app/authors', { replace: true });
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  updateAuthor = flow(function*(id, data) {
    this.loading = true;
    try {
      yield authorSevice.updateAuthor(id, data);
      this.rootStore.alert.setSuccessMessage('Author data saved');
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  delete = flow(function*(id) {
    this.loading = true;
    try {
      const result = yield authorSevice.deleteAuthor(id);
      if (result.ok) {
        yield this.fetchAuthors();
        this.rootStore.alert.setSuccessMessage('Author has been removed');
      } else {
        this.rootStore.alert.setErrorMessage('Failed to remove author');
      }
    } catch (err) {
      console.error(err);
      this.error = err;
      this.rootStore.alert.setError(err);
    } finally {
      this.loading = false;
    }
  });

  changePage = flow(function*(newPage) {
    if (newPage === this.page) {
      return;
    }
    this.page = newPage;
    yield this.fetchAuthors();
  });

  setFilter({ name, all }) {
    this.filter = {
      name,
      all,
    };
  }
}

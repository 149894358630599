import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store/store';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(them => ({
  box: {
    marginBottom: them.spacing(2),
  },
}));
const Toolbar = observer(props => {
  const { className } = props;
  const classer = useStyles();
  const { category } = useStore();
  const [nameSearch, setNameSearch] = useState(category.filter.name);

  const handleChangeSearch = useCallback(e => {
    const { value } = e.target;
    setNameSearch(value);
  }, []);
  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        category.setFilter({ name: nameSearch });
        category.fetchCategories();
      }
    },
    [nameSearch]
  );
  return (
    <>
      <div className={className}>
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            component={NavLink}
            to="/app/categories/create"
          >
            Add category
          </Button>
        </Box>
      </div>
      <Box className={classer.box} mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                value={nameSearch}
                placeholder="Search customer"
                variant="outlined"
                onChange={handleChangeSearch}
                onKeyPress={handleKeyPress}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
});

Toolbar.propTypes = {
  className: PropTypes.string,
  onSearchTextSubmit: PropTypes.func,
};

export default Toolbar;

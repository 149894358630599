import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Divider,
  CardContent,
  TextField,
  Box,
  Button,
  makeStyles,
  FormControl,
  FormLabel,
  MenuItem,
} from '@material-ui/core';
import {
  BasicForm,
  FormField,
  FileUploadField,
  CategoryDropdown,
  AuthorDropdown,
  LanguageDropdown,
} from 'src/components/form';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(them => ({
  buttonCancel: {
    marginRight: them.spacing(2),
  },
  alignLabelcheckbox: {
    marginLeft: them.spacing(2),
  },
  thumb: {
    width: 250,
    marginTop: 10,
  },
}));

const BookForm = observer(props => {
  const classer = useStyles();
  const { crawledBook, category } = useStore();
  let { editId } = props;
  if (editId) {
    editId = parseInt(editId, 10);
  }
  const { categoriesWithParentName } = category;
  const { editingBook } = crawledBook;
  const [initialValue, setInitialValue] = useState({
    name: '',
    description: '',
    categoryId: '',
    authorName: '',
    sourceUrl: '',
    source: '',
    imageUrl: '',
    bookFileUrl: '',
    editedName: '',
    editedDescription: '',
    editedAuthorId: '',
    editedCategoryId: '',
    editedImage: undefined,
    editedBookFile: undefined,
    status: 0,
    lang: 'vi',
  });

  const setupInitialVaues = useCallback(book => {
    setInitialValue({
      name: book.name,
      description: book.description,
      categoryId: book.categoryId,
      authorName: book.authorName,
      sourceUrl: book.sourceUrl,
      source: book.source,
      imageUrl: book.imageUrl,
      bookFileUrl: book.bookFileUrl,
      editedName: book.editedName || '',
      editedDescription: book.editedDescription || '',
      editedAuthorId: book.editedAuthorId || '',
      editedCategoryId: book.editedCategoryId || '',
      editedImage: book.editedImageUrl,
      editedBookFile: book.editedBookFileUrl,
      status: book.status,
      lang: book.lang,
    });
  }, []);

  useEffect(() => {
    category.setFilter({ name: null, all: true });
    category.fetchCategories();
  }, [category]);

  useEffect(() => {
    if (editId) {
      crawledBook.fetchBook(editId);
    }
  }, [editId, crawledBook]);

  const handleSave = useCallback(
    values => {
      console.log({ values });
      crawledBook.updateBook(editId, values, book => {
        console.log({ book });
        setupInitialVaues(book);
      });
    },
    [crawledBook, editId]
  );

  useEffect(() => {
    if (editId && editingBook.id) {
      setupInitialVaues(editingBook);
    }
  }, [editId, editingBook.id]);

  return (
    <BasicForm
      title="Crawled Book"
      subheader="Update crawled book"
      initialValues={initialValue}
      enableReinitialize
      onSubmit={handleSave}
    >
      <CardContent>
        <FormField
          component={TextField}
          fullWidth
          label="Source"
          margin="normal"
          name="source"
          variant="outlined"
          disabled
        />
        <FormControl fullWidth margin="normal">
          <FormLabel>Source URL</FormLabel>
          {initialValue.sourceUrl && (
            <div>
              <a href={initialValue.sourceUrl} target="_blank" rel="noreferrer">
                {initialValue.sourceUrl}
              </a>
            </div>
          )}
        </FormControl>

        <FormField
          component={TextField}
          fullWidth
          label="Name"
          margin="normal"
          name="name"
          variant="outlined"
          disabled
        />
        <FormField
          component={TextField}
          fullWidth
          label="Edited Name"
          margin="normal"
          name="editedName"
          variant="outlined"
        />
        <CategoryDropdown name="categoryId" data={categoriesWithParentName} />
        <CategoryDropdown
          name="editedCategoryId"
          data={categoriesWithParentName}
          label="Edited Category"
        />
        <FormField
          component={TextField}
          fullWidth
          label="Author Name"
          margin="normal"
          name="authorName"
          variant="outlined"
          disabled
        />
        <AuthorDropdown name="editedAuthorId" label="Edited Author" />

        <LanguageDropdown />

        <FormField
          component={TextField}
          fullWidth
          label="Description"
          margin="normal"
          name="description"
          variant="outlined"
          multiline
          rows={8}
          disabled
        />
        <FormField
          component={TextField}
          fullWidth
          label="Edited Description"
          margin="normal"
          name="editedDescription"
          variant="outlined"
          multiline
          rows={8}
        />
        <FormControl fullWidth margin="normal">
          <FormLabel>Image</FormLabel>
          {initialValue.imageUrl && (
            <img alt="crawled thumb" src={initialValue.imageUrl} className={classer.thumb} />
          )}
        </FormControl>
        <FileUploadField label="Edited Image" name="editedImage" filesLimit={1} />

        {editingBook?.externalBookLink && (
          <FormControl fullWidth margin="normal">
            <FormLabel>Book File Link</FormLabel>
            <div>
              <a href={editingBook?.externalBookLink} target="_blank" rel="noreferrer">
                {editingBook?.externalBookLink}
              </a>
            </div>
          </FormControl>
        )}

        <FormControl fullWidth margin="normal">
          <FormLabel>Book File URL</FormLabel>
          {initialValue.bookFileUrl && (
            <div>
              <a href={initialValue.bookFileUrl} target="_blank" rel="noreferrer">
                {initialValue.bookFileUrl}
              </a>
            </div>
          )}
        </FormControl>
        <FileUploadField label="Edited Book File" name="editedBookFile" filesLimit={1} />
        <FormField
          component={TextField}
          fullWidth
          select
          label="Status"
          margin="normal"
          name="status"
          variant="outlined"
        >
          <MenuItem value="0">Unapproved</MenuItem>
          <MenuItem value="1">Approved</MenuItem>
          <MenuItem value="2">Rejected</MenuItem>
        </FormField>
      </CardContent>
      <Divider />
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button
          variant="contained"
          component={NavLink}
          to="/app/crawledBooks"
          className={classer.buttonCancel}
        >
          {initialValue.status === 1 ? 'Back' : 'Cancel'}
        </Button>
        {initialValue.status !== 1 && (
          <Button type="submit" color="primary" variant="contained" disabled={crawledBook.loading}>
            Save
          </Button>
        )}
      </Box>
    </BasicForm>
  );
});

export default BookForm;

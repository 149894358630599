export const makeFormData = (data, formData = null, namespace = '') => {
  if (!formData) {
    formData = new FormData();
  }
  if (Array.isArray(data)) {
    data.forEach((it, i) => {
      makeFormData(it, formData, `${namespace}[${i}]`);
    });
  } else if (data && typeof data === 'object' && !(data instanceof File || data instanceof Blob)) {
    Object.keys(data).forEach(fieldName => {
      const val = data[fieldName];
      makeFormData(val, formData, namespace ? `${namespace}.${fieldName}` : fieldName);
    });
  } else {
    formData.append(namespace, data);
  }

  return formData;
};

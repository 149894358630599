import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/store/store';
import { TextField, MenuItem } from '@material-ui/core';
import { FormField } from 'src/components/form';

const CategoryDropdown = observer(props => {
  const { data, ...rest } = props;
  const { category } = useStore();
  const { categoriesWithParentName } = category;
  useEffect(() => {
    if (!data) {
      category.setFilter({ name: null, all: true });
      category.fetchCategories();
    }
  }, [category, data]);
  const categories = useMemo(() => {
    return data || categoriesWithParentName;
  }, [data, categoriesWithParentName]);

  return (
    <FormField
      component={TextField}
      fullWidth
      select
      label="Category"
      margin="normal"
      name="categoryId"
      variant="outlined"
      {...rest}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>

      {categories.map(cat => {
        return (
          <MenuItem key={cat.id} value={cat.id}>
            {cat.name}
          </MenuItem>
        );
      })}
    </FormField>
  );
});

export default CategoryDropdown;

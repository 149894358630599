import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  // AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  // Lock as LockIcon,
  // Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Package as PackageIcon,
  MessageCircle as MessageCircleIcon,
  // User as UserIcon,
  // UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Folder as FolderIcon,
} from 'react-feather';
import { observer } from 'mobx-react-lite';

import NavItem from './NavItem';
import { useStore } from '../../../store/store';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
};

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
  },
  {
    href: '/app/categories',
    icon: FolderIcon,
    title: 'Categories',
  },
  {
    href: '/app/books',
    icon: ShoppingBagIcon,
    title: 'Books',
  },
  {
    href: '/app/authors',
    icon: UsersIcon,
    title: 'Author',
  },
  {
    href: '/app/crawledBooks',
    icon: ShoppingBagIcon,
    title: 'Crawled Books',
  },
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'User',
  },
  {
    href: '/app/contactMesssages',
    icon: PackageIcon,
    title: ' Contact Messages',
  },
  {
    href: '/app/newletters',
    icon: MessageCircleIcon,
    title: 'Newsletter',
  },
  // {
  //   href: '/app/customers',
  //   icon: UsersIcon,
  //   title: 'Customers',
  // },

  // {
  //   href: '/app/products',
  //   icon: ShoppingBagIcon,
  //   title: 'Products',
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Account',
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings',
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login',
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register',
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error',
  // },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = observer(({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const { auth } = useStore();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {auth.user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Administrator
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
});

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;

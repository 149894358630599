import React, { useEffect, useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import IconButton from '@material-ui/core/IconButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useStore } from 'src/store/store';
import { NavLink } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import config from '../../../config/config';
import ConfirmDialog from '../../../components/dialog/ConfirmDialog';

const ContactList = observer(() => {
  const { contactMessage } = useStore();
  const { contactMessages, page, total } = contactMessage;
  const [openDialog, setOpenDialog] = useState(false);
  const deletingContactIdRef = useRef(null);
  useEffect(() => {
    contactMessage.fetchContactMessages();
  }, [contactMessage]);
  const handlePageChange = useCallback((_, newPage) => {
    contactMessage.changePage(newPage + 1);
  });

  const handleDeleteClick = useCallback(id => {
    deletingContactIdRef.current = id;
    setOpenDialog(true);
  });
  const handleCancelDelete = useCallback(() => {
    setOpenDialog(false);
  });
  const handleSubmitDelete = useCallback(() => {
    contactMessage.deleteContactMessage(deletingContactIdRef.current);
    setOpenDialog(false);
  }, [contactMessage]);
  return (
    <Box mt={3}>
      <Card>
        <PerfectScrollbar>
          <Box minWidth={300}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>UserID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {contactMessages.map(contactItem => {
                  return (
                    <>
                      <TableRow key={`contact ${contactItem.id}`}>
                        <TableCell>{contactItem.userId ? contactItem.userId : ''}</TableCell>

                        <TableCell>
                          {contactItem.user ? contactItem.user.name : contactItem.name}
                        </TableCell>
                        <TableCell>
                          {contactItem.user ? contactItem.user.email : contactItem.email}
                        </TableCell>

                        <TableCell>
                          {moment(contactItem.createdAt).format('DD-MM-YYYY HH:mm:ss')}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            component={NavLink}
                            to={`/app/contactMesssages/views/${contactItem.id}`}
                          >
                            <VisibilityIcon size="small" />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon
                              size="small"
                              onClick={() => handleDeleteClick(contactItem.id)}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={total}
          rowsPerPageOptions={[]}
          page={page - 1}
          onChangePage={handlePageChange}
          rowsPerPage={config.itemsPerPage}
        />
      </Card>
      <ConfirmDialog
        title="Delete Confirmation"
        message="Are you sure want to delete ContactMessage?"
        open={openDialog}
        onClose={handleCancelDelete}
        onOk={handleSubmitDelete}
      />
    </Box>
  );
});

export default ContactList;

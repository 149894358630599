import * as paginationUtils from 'src/utils/pagination';

export class AuthorService {
  _apiService;

  constructor(apiService) {
    this._apiService = apiService;
  }

  async fetchAuthors(filter, page, pageSize) {
    let authors = [];
    let total = 0;
    let p = page || 1;
    const fechAll = filter?.all;
    while (true) {
      const data = await this._apiService.get('authors', {
        params: {
          ...filter,
          ...paginationUtils.getPaginationFilter(p, pageSize),
        },
      });
      total = data.total;
      authors = [...authors, ...data.data];
      if (!fechAll || authors.length >= total) {
        break;
      }
      p++;
    }
    return {
      total,
      authors,
    };
  }

  async fetchAuthor(authorId) {
    return this._apiService.get(`authors/${authorId}`);
  }

  createAuthor(data) {
    return this._apiService.post(`authors`, {
      data,
    });
  }

  updateAuthor(id, data) {
    return this._apiService.put(`authors/${id}`, {
      data,
    });
  }

  deleteAuthor(id) {
    return this._apiService.delete(`authors/${id}`);
  }
}

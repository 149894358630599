import * as paginationUtils from 'src/utils/pagination';

export class CategoryService {
  _apiService;

  constructor(apiService) {
    this._apiService = apiService;
  }

  async fetchCategories(filter, page, pageSize) {
    let categories = [];
    let total = 0;
    let p = page || 1;
    const fetchAll = filter?.rootOnly || filter?.all;
    while (true) {
      const data = await this._apiService.get('categories', {
        params: {
          ...filter,
          ...paginationUtils.getPaginationFilter(p, pageSize),
        },
      });
      total = data.total;
      categories = [...categories, ...data.data];
      if (!fetchAll || categories.length >= total) {
        break;
      }
      p++;
    }

    return { total, categories };
  }

  async fetchCategory(categoryId) {
    return this._apiService.get(`categories/${categoryId}`);
  }

  createCategory(data) {
    if (!data.parentId && typeof data.parentId !== 'undefined') {
      delete data.parentId;
    }
    return this._apiService.post('categories', {
      data,
    });
  }

  updateCategory(id, data) {
    if (!data.parentId && typeof data.parentId !== 'undefined') {
      delete data.parentId;
    }
    return this._apiService.put(`categories/${id}`, {
      data,
    });
  }

  deleteCategory(id) {
    return this._apiService.delete(`categories/${id}`);
  }
}
